import React, { useRef, useEffect, useState} from 'react';

const Canvas = (props) => {
  const canvasRef = useRef(null);
  const colourArray = ['#95ADE6', '#4F7AE0', '#3F4961'];
  const maxRadius = 40;


  const[scrollOpacity, setScrollOpacity] = useState(0.8);

  useEffect(() => {
    const circleArray = [];

    const canvas = canvasRef.current;
    const c = canvas.getContext('2d');

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const mouse = {
      x: undefined,
      y: undefined
    };

    window.addEventListener('mousemove', (event) => {
      mouse.x = event.x;
      mouse.y = event.y;
    });

    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    });

    window.addEventListener("scroll", (event)=>{
      const scrollTop = document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const windowHeight = document.documentElement.clientHeight;
      const scrollPercentage = (0.8 - (scrollTop / (scrollHeight - windowHeight)));
      setScrollOpacity(scrollPercentage);
    });

    function Circle(x, y, dx, dy, radius) {
      this.x = x;
      this.y = y;
      this.dx = dx;
      this.dy = dy;
      this.radius = radius;
      this.minRadius = radius;
      this.colour = colourArray[Math.floor(Math.random() * colourArray.length)];

      this.draw = function () {
        c.beginPath();
        c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        c.fillStyle = this.colour;
        c.fill();
      };

      this.update = function () {
        if (this.x + this.radius > window.innerWidth || this.x - this.radius < 0) { // If it hits the sides, change direction
          this.dx = -this.dx;
        }

        if (this.y + this.radius > window.innerHeight || this.y - this.radius < 0) {// If it hits the top/bottom, change direction
          this.dy = -this.dy;
        }


        //Interaction
        if (mouse.x - this.x < 50 && mouse.x - this.x > -50 && mouse.y - this.y < 50 && mouse.y - this.y > -50){// If it's within 50px of the mouse

            if (this.y > mouse.y) {  // If it's lower than the mouse
              if (this.dy < 0) { // if it's going up, make it go down
                this.dy = -this.dy;
              }
            }
            if (this.y < mouse.y) {  // If it's higher than the mouse
              if (this.dy > 0) { // if it's going down, make it go up
                this.dy = -this.dy;
              }
            }

            if (this.x > mouse.x) {  // If it's to the right of mouse
              if (this.dx < 0) {
                this.dx = -this.dx;
              }
            }
            if (this.x < mouse.x) {  // If it's to the right of mouse
              if (this.dx > 0) {
                this.dx = -this.dx;
              }
            }
        }

        this.x += this.dx;
        this.y += this.dy;

        this.draw();
      };
    }

    function init(){
      if (window.innerWidth > 1280) {
        for (let i = 0; i < (300 * props.buttonClicked); i++) {
          const spawnRadius = 40;

          const radius = Math.random() * 1.4 + 0.1;

          // Generate a random angle and distance for each dot within the circular area
          const angle = Math.random() * Math.PI * 2;
          const distanceFromCenter = Math.random() * spawnRadius; // spawnRadius is the max distance from the center

          // Calculate the new x and y positions for the dot
          const x = props.nPosition.x + Math.cos(angle) * distanceFromCenter;
          const y = props.nPosition.y + Math.sin(angle) * distanceFromCenter;

          // Set the velocity based on the direction from the center, with a random speed factor
          const speed = Math.random() * 2 + 1; // You can tweak this value to make it faster/slower
          const dx = Math.cos(angle) * speed;
          const dy = Math.sin(angle) * speed;

          circleArray.push(new Circle(x, y, dx, dy, radius));

        }
      } else{
        for (let i = 0; i < 300; i++) {
          const radius = Math.random() * 1.2 + 0.1;
          const x = props.nPosition.x;
          const y = props.nPosition.y;
          const dx = (Math.random() - 0.5) * 2;
          const dy = (Math.random() - 0.5) * 2;

          circleArray.push(new Circle(x, y, dx, dy, radius));
        }
      }
    }


    if (props.buttonClicked) {
      init();
    }

    function animate() {
      requestAnimationFrame(animate);
      c.clearRect(0, 0, window.innerWidth, window.innerHeight);
      for (let i = 0; i < circleArray.length; i++) {
        circleArray[i].update();
      }
    }
    animate();
  }, [props.buttonClicked]);



  return (
        <canvas className='canvas' ref={canvasRef} style={{opacity:scrollOpacity}}></canvas>

    );
};

export default Canvas;